import React from 'react';
import './App.css';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {greeting: null};
  }

  fetchdata() {
    var url = "https://internal.jacobmossberg.se:20443/api/fundmonitor/greeting";
    fetch(url)
        .then(res => res.json())
        .then((data) => {
          this.setState({greeting: data});
          console.log(this.state)
        })
        .catch(console.log)
  }

  componentDidMount() {
    this.fetchdata()
  }

  render() {
    return (
        <p>greeting: {JSON.stringify(this.state.greeting)}</p>
    );
  }

}

export default App;

